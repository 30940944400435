@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-4
        cursor-pointer
    }

    button {
        @apply text-white border bg-[#004173] border-[#004173] hover:bg-transparent hover:text-[#004173] rounded-md
    }
}